:root {
    --color1: rgba(215, 10, 132, 1);
    --color1-low-opacity: rgba(215, 10, 132, 0.35);
    --color2: rgba(81, 18, 127, 1);
    --color2-low-opacity: rgba(81, 18, 127, 0.4);
    --color3: #333;
    --color4: #00c260;
}
html {
        
}
body {
    display: flex;
    height: 100%;
}
a {
    color: #0057c2;
    font-weight: bold;
    flex-direction: column;
}

#app {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#main {
    min-width: 375px;
    max-width: 1100px;
    width: 85%;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

#logo {
    display: flex;
    justify-content: center;
    align-items: center;
}
#logo img {
    width: 50%;
    min-width: 360px;
    max-width: 500px;
}

#intro {
    padding: 1em;
    line-height: 1.5rem;
}
#footer {
    padding: 2rem;
    background: linear-gradient(#ddd, #fff 90%);
    text-align: center;
    margin-top: 100px;
    width: 100%;
}
/* search box */
#search {
    align-self: center;
    min-width: 300px;
}
#search form {
    background: linear-gradient(var(--color1), var(--color2) 90%);
    padding: 20px;
    border-radius: 10px;
    color: #fff;
    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.75));
    border: 1px solid #fff;
    margin-bottom: 35px;
}

#first-row {
    display: flex;
    flex-direction: row;
}

#term {
    flex-grow: 5;
    padding-right: 5px;
}

#tld {
    flex-grow: 1;
}

#second-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#options {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 10px;
    margin-right: 40px;
    margin-top: 10px;
    flex-wrap: wrap;
}

#max-length {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}

#max-length-display {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background: var(--color1);
    padding: 5px;
    border-radius: 5px;

}

#max-length-slider {
    min-width: 75px;
    max-width: 125px;
}

input[type="range"]::-webkit-slider-thumb {
    background: var(--color1) !important;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
    background: var(--color1) !important;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
    background: var(--color1) !important;
}

input[type="checkbox"]:checked {
    accent-color: black;
    background-color: var(--color1);
}




/* search results */
#search-results {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
}
#no-results {
    text-align: center;
    font-weight: bold;
}
.result {
    background: #eee;
    border-radius: 10px;
    padding: 10px;
    width: 15%;
    min-width: 250px;
    height: 125px;
    overflow-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, 0);
    overflow: hidden;
    position: relative;
}

.result p {
    display: block;
    color: #999;
    margin: 0.5rem;
    clear: both;
}
.result ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
}
.result button {
    border: 0;
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 0.8rem;
    color: #666;
}
.result img {
    width: 25px;
    height: 25px;
    float: left;
    margin-right: 5px;
}
.result:hover {
    cursor: pointer;
    color: var(--color2);
    filter: drop-shadow(0 0 10px var(--color2-low-opacity));
    border: 1px solid #fff;
}
.result:hover .definition {
    color: #000;
}
.result:hover button {
    color: #000;
}
.result .available {
    color: var(--color4);
    padding: 3px;
    font-weight: bold;
    font-size: 0.7rem;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 2px 5px;
    margin: 0 2px 2px 0;
}

/* help tips */
.help-tip {
    position: relative;
    z-index: 1000;
}
.help-tip-description::before {
    content: '?';
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background: var(--color1-low-opacity);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: normal;
    position: absolute;
    margin: auto 0 auto 100%;
    top: 0;
    bottom: 0;
    right: -30px;
}

.help-tip-description:hover::before {
    background: var(--color1-low-opacity);
    color: #000;
}

.help-tip-description p {
    font-size: 0.8rem;
    font-weight: normal;
    position: absolute;
    display: none;
    background: #000;
    margin: auto 0 auto 100%;
    padding: 10px;
    left: 0;
    top: 30px;
    width: 250px;
    color: #fff;
    border-radius: 5px;
}
.help-tip-align-left .help-tip-description p {
    left: 0px;
}

.help-tip-description:hover p {
    display: block;
}

/* registration modal */
#registration-modal {
    border-color: #000;
}
#registration-modal-header {
    background-color: var(--color3);
    color: #fff;
}
#registration-modal-body {
    background-color: #fff;
    color: #000;
    position: relative;
}
#registration-modal-footer {
    background-color: var(--color3);
}

#registration-modal-footer button {
    background-color: #000;
    border: none;
}
#registration-modal-definition {
    margin-top: 0.5rem;
}
#registration-modal-definition span {
    font-style: italic;
}
#registration-modal-available {
    color: var(--color4);
    padding: 3px;
    font-weight: bold;
    font-size: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px;
    margin: 0 2px 2px 0;
}
#registration-modal-registrars {
    list-style: none;
    margin: 1rem 0;
    padding: 0;
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
}
#registration-modal-registrars button {
    border-radius: 5px;
    background-color: var(--color1);
    color: #fff;
    padding: 3px 8px;
    margin: 0.5rem 10px;
}